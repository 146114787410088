import { memo } from "react"
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { useImage } from "@app/hooks/useImage"
import { SiteLogo } from "@app/components/SiteLogo"
import Main from "@components/Main"
import MaintenanceForm from "./MaintenanceForm"

import type { Props } from "@app/pages/password"
import type { PageProps } from "@root/types/global"

const Maintenance: React.FC<PageProps<Props>> = ({ location, page }) => {
  const { title, content, image, bgColor } = page ?? {}
  const { getGatsbyImage } = useImage()
  const responsiveImage = image?.asset ? getGatsbyImage(image, { maxWidth: 1000 }) : false

  return (
    <Main>
      <Flex minH="100vh" direction={{ base: "column", md: "row" }}>
        <Box display={{ base: "none", md: "block" }} maxW={{ base: "20rem", lg: "40rem" }} flex="1" bgColor={bgColor || "brand.offWhite"}>
          {image && <Image objectFit="cover" w="full" h="full" {...responsiveImage} />}
        </Box>
        <Box flex="1" px={{ base: "6", md: "10", lg: "16", xl: "28" }} py={{ base: "6", md: "64" }}>
          <Box maxW="xl">
            <Box>
              <Box mb="16">
                <SiteLogo />
              </Box>
              <Heading as="h1" size="2xl" fontWeight={600}>
                {title}
              </Heading>
              <Text mt="3" fontSize={{ base: "xl", md: "2xl" }}>
                {content}
              </Text>
            </Box>
            <Box mt="10" maxW="80">
              <MaintenanceForm location={location} />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Main>
  )
}

export default memo(Maintenance)
